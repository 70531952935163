<template>
 <el-card shadow="never" style="height: 100%">
   <el-form
       :model="ruleForm"
       status-icon
       :rules="rules"
       ref="ruleForm"
       label-width="100px"
       class="demo-ruleForm"
       style="margin-top:20px"
   >
     <el-form-item label="类型名称" prop="name">
       <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
     </el-form-item>
     <el-form-item label="英文名称" prop="enName">
       <el-input v-model="ruleForm.enName" autocomplete="off"></el-input>
     </el-form-item>
     <el-form-item label="所属客户" prop="customerId" v-show="isshow()">
       <el-select v-model="ruleForm.customerId" placeholder="请选择">
         <el-option
             v-for="item in customerOptions"
             :key="item.id"
             :label="item.name"
             :value="item.id"
         >
         </el-option>
       </el-select>
     </el-form-item>
     <el-form-item>
       <el-button type="primary" @click="addmodular('ruleForm')" style="background: #1d90ff; border:none">提交</el-button>
     </el-form-item>
   </el-form>
 </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";
import Cookies from 'js-cookie';
export default {
  methods: {
    isshow(){
      return this.customeshow 

    },
     addmodular(ruleForm) {
        this.show = true;
       if(this.userInfo != 0){
         console.log(this.userInfo)
          this.ruleForm.customerId = this.userInfo
       }
  
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
        "/product/update/type",
        null,
        {
         id:this.ruleForm.id,
         name: this.ruleForm.name,
         customerId:this.ruleForm.customerId,
         enName: this.ruleForm.enName,
        },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              this.$router.push({name: "modulartypelist"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
          
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    init() {
      const articleId = this.$route.query.id;
      this.getproductById(articleId);
    },
     getproductById(id) {
      this.$api.get(
          "/product/getProductTypeById",
          null,
          {id: id},
          (successRes) => {
            console.log(successRes);
            this.ruleForm = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getUserInfoByUserName() {
      console.log(Cookies.get('username'));
      const username = Cookies.get('username')
      this.$api.get(
          "/user/getUserInfoByUserName",
          null,
          {
            username: username
          },
          (successRes) => {
            
            
            
              this.customeshow = false
              this.userInfo = successRes.data.customerId;
              console.log(this.userInfo);
              this. getcustomer();
              
            this.customeshow = successRes.data.customerId == 0;
            },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
     getcustomer() {
      this.$api.get(
        "/customer/list",
        null,
        {
          list: false,
        },
        (successRes) => {
          this.customerOptions = successRes.data.rows;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
  },
  data() {
    return {
       rules: {
        name: [
          {required: true, message: "请输入标题", trigger: "blur"},
        ],
        enName: [
          {required: true, message: "请输入英文名称", trigger: "blur"},
        ],
      },
      customerOptions:[],
      ruleForm: {
        type: "",
        name:"",
        customerId:null,
        enName:'',
        
      },
      
    };
  },
  mounted(){
    this.init();
    
    this.getUserInfoByUserName();
  },
  
};
</script>